import { useEffect, useRef, useState } from 'react';
import { debounce } from 'lodash';
import { Form } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import AsyncSelect from 'react-select/async';
import Select from 'react-select';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import DatePicker from '../../DatePicker';
import FormButton from '../../Button';

import { correspondent } from '../../../redux/reducers/correspondents/selectors';
import { selectAuthToken, selectCurrentUserId } from '../../../redux/reducers/authentication/selectors';

import { createLoader, updateLoader } from '../../../redux/reducers/courriersArrivee/selectors';
import { selectUserByRoleData, selectUserRole } from '../../../redux/reducers/user/selectors';

import clients from '../../../config/clients';
import { LIST_OF_ROLES, LIST_OF_ROLES_KEYS, WORKFLOW_STATE } from '../../../constants';
import { selectActions } from '../../../redux/reducers/actions/selectors';
import { getActions } from '../../../redux/reducers/actions/actionCreators';
import { getUsersByRole } from '../../../redux/reducers/user/actionCreators';

const StyleButtonWrapper = styled.div`
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-end;
  flex-direction: row;
  padding-top: calc(42px - 16px);
`;

const StyledFormGroup = styled(Form.Group)`
  padding-bottom: 16px;

  label {
    color: #3D5278;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
  }
`;

const axiosApiInstance = clients.api.client;

function RegisterArriveForm({ data, onSubmit, onClose, isEdit }) {
  const fetchSearchResultsRef = useRef();
  const dispatch = useDispatch();

  const authToken = useSelector(selectAuthToken);
  const correspondentById = useSelector(correspondent(data?.correspondant_id));
  const isCreating = useSelector(createLoader);
  const isUpdating = useSelector(updateLoader);
  const userId = useSelector(selectCurrentUserId);
  const userRole = useSelector(selectUserRole(userId));
  const actions = Object.values(useSelector(selectActions));
  const usersByRole = Object.values(useSelector(selectUserByRoleData));

  const role = LIST_OF_ROLES[userRole];

  const [formData, setFormData] = useState({
    correspondant_id: '',
    date_correspondance: '',
    reference: '',
    user_id: userId,
    workflow_state: role === LIST_OF_ROLES_KEYS.STANDARDISTE ? WORKFLOW_STATE.TRANSMITTED_SECRETARY : '',
    commentaire: '',
  });

  const onChangeHandler = (field) => (val) => {
    setFormData((prevState) => ({
      ...prevState,
      [field]: val,
    }));
  };

  const correspondents = (inputValue, callback) => {
    if (fetchSearchResultsRef.current) {
      fetchSearchResultsRef?.current?.cancel();
    }
    fetchSearchResultsRef.current = debounce(async () => {
      const res = await axiosApiInstance.request({
        method: 'get',
        url: '/correspondants',
        params: { limit: 100, page: 1, correspondent_name: inputValue },
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });
      callback(res?.data?.correspondants?.map((val) => ({
        ...val,
        label: val?.nom,
        value: val?.id,
      })));
    }, 500, { maxWait: 700, trailing: true, leading: false });
    fetchSearchResultsRef.current();
  };

  const onSubmitHandler = () => {
    formData.workflow_state = WORKFLOW_STATE.TRANSMITTED_SECRETARY;

    if (role === LIST_OF_ROLES_KEYS.AGENT_SERVICE && formData?.scan) {
      formData.workflow_state = WORKFLOW_STATE.TRANSMITTED_GENERAL_MANAGER;
    }
    if (role === LIST_OF_ROLES_KEYS.DIRECTEUR_GENERAL && formData?.user_courriers_attributes?.length > 0) {
      formData.workflow_state = WORKFLOW_STATE.TRANSMITTED_SERVICE_MANAGER;
    }
    if (role === LIST_OF_ROLES_KEYS.DIRECTEUR_SERVICE && formData?.user_courriers_attributes?.length > 0) {
      formData.workflow_state = WORKFLOW_STATE.TRANSMITTED_SERVICE_AGENT;
    }
    if (formData?.scan) {
      formData.workflow_state = WORKFLOW_STATE.TRANSMITTED_GENERAL_MANAGER;
    }
    onSubmit({
      ...formData,
      correspondant_id: formData?.correspondant_id?.id,
      user_courriers_attributes: formData?.user_courriers_attributes?.map((user) => ({ user_id: user.value })),
      instruction_courriers_attributes: formData?.instruction_courriers_attributes?.map((instruction) => ({ instruction_id: instruction.value })),
    });
  };

  useEffect(() => {
    dispatch(getActions());
    dispatch(getUsersByRole({ role_id: userRole + 1 }));
  }, []);

  useEffect(() => {
    if (!isEmpty(data)) {
      setFormData((prevState) => ({
        ...prevState,
        ...data,
        correspondant_id: { ...correspondentById, label: correspondentById?.nom },
      }));
    }
  }, [data]);

  return (
    <form>
      {(role === LIST_OF_ROLES_KEYS.DIRECTEUR_GENERAL || role === LIST_OF_ROLES_KEYS.STANDARDISTE || role === LIST_OF_ROLES_KEYS.SECRETAIRE
        || role === LIST_OF_ROLES_KEYS.DIRECTEUR_SERVICE) && (
        <>
          <StyledFormGroup
            controlId="correspondant"
          >
            <Form.Label>
              Correspondant
            </Form.Label>
            <AsyncSelect
              defaultOptions
              cacheOptions
              loadOptions={correspondents}
              placeholder="Choisir Correspondant"
              value={formData?.correspondant_id}
              onChange={onChangeHandler('correspondant_id')}
            />
          </StyledFormGroup>
          <StyledFormGroup>
            <DatePicker
              value={formData?.date_correspondance}
              placeholder="Select your date"
              label="Date Correspondance"
              onDateChange={onChangeHandler('date_correspondance')}
            />
          </StyledFormGroup>
          <StyledFormGroup
            controlId="reference"
          >
            <Form.Label>Référence</Form.Label>
            <Form.Control
              name="reference"
              placeholder="Enter you référence"
              value={formData?.reference}
              type="text"
              onChange={({ target: { value } }) => onChangeHandler('reference')(value)}
            />
          </StyledFormGroup>
        </>
      )}
      {(role === LIST_OF_ROLES_KEYS.DIRECTEUR_GENERAL || role === LIST_OF_ROLES_KEYS.SECRETAIRE || role === LIST_OF_ROLES_KEYS.DIRECTEUR_SERVICE) && (
        <>
          <StyledFormGroup>
            <DatePicker
              value={formData?.date_edition}
              placeholder="Select your date"
              label="Date edition"
              onDateChange={onChangeHandler('date_edition')}
            />
          </StyledFormGroup>
          <StyledFormGroup
            controlId="objet"
          >
            <Form.Label>Objet</Form.Label>
            <Form.Control
              name="objet"
              placeholder="Enter you objet"
              value={formData?.objet}
              type="text"
              onChange={({ target: { value } }) => onChangeHandler('objet')(value)}
            />
          </StyledFormGroup>
          <StyledFormGroup
            controlId="scan"
          >
            <Form.Label>Scan</Form.Label>
            <Form.Control
              name="scan"
              placeholder="Enter you objet"
              type="file"
              onChange={({ target: { files } }) => onChangeHandler('scan')(files[0])}
            />
          </StyledFormGroup>
        </>
      )}
      {(role === LIST_OF_ROLES_KEYS.DIRECTEUR_GENERAL || role === LIST_OF_ROLES_KEYS.DIRECTEUR_SERVICE) && (
        <>
          <StyledFormGroup
            controlId="actions"
          >
            <Form.Label>
              Actions
            </Form.Label>
            <Select
              defaultValue={formData?.action_courriers_attributes}
              isMulti
              onChange={onChangeHandler('action_courriers_attributes')}
              options={actions.map((val) => ({ ...val, label: val?.nom, value: val?.id }))}
              placeholder="Choisir actions"
            />
          </StyledFormGroup>
          <StyledFormGroup
            controlId="collaborators"
          >
            <Form.Label>
              Choisir les collaborateurs
            </Form.Label>
            <Select
              defaultValue={formData?.user_courriers_attributes}
              isMulti
              onChange={onChangeHandler('user_courriers_attributes')}
              options={usersByRole.map((val) => {
                const name = [val?.first_name, val?.last_name].filter((val) => val).join(' ');
                return { ...val, label: `${name} (${val?.email})`, value: val?.id };
              })}
              placeholder="Choisir les collaborateurs"
            />
          </StyledFormGroup>
          {isEdit && (
            <StyledFormGroup
              controlId="commentaire"
            >
              <Form.Label>
                Commentaire
              </Form.Label>
              <Form.Control
                as="textarea"
                value={formData.commentaire}
                onChange={({ target: { value } }) => {
                  onChangeHandler('commentaire')(value);
                }}
              />
            </StyledFormGroup>
          )}
        </>
      )}
      <StyleButtonWrapper>
        <FormButton
          onClick={() => onClose()}
          isLoading={isCreating || isUpdating}
          title="Fermer"
        />
        <FormButton
          variant="primary"
          size="lg"
          onClick={onSubmitHandler}
          isLoading={isCreating || isUpdating}
          title="Enregistrer"
        />
      </StyleButtonWrapper>
    </form>
  );
}

RegisterArriveForm.propTypes = {
  data: PropTypes.shape({ correspondant_id: PropTypes.number }),
  isEdit: PropTypes.bool,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
};

RegisterArriveForm.defaultProps = {
  data: {},
  isEdit: false,
  onClose: () => { },
  onSubmit: () => { },
};

export default RegisterArriveForm;
